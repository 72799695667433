export const BANAN_FIREBASE = {
  apiKey: "AIzaSyCWJP9j_5iDoUFQkJKxi2pX05h4M9r7IEA",
  authDomain: "banan-13ee5.firebaseapp.com",
  databaseURL: "https://banan-13ee5-default-rtdb.firebaseio.com",
  projectId: "banan-13ee5",
  storageBucket: "banan-13ee5.appspot.com",
  messagingSenderId: "565624244581",
  appId: "1:565624244581:web:f0278e326565a8b608398c",
  measurementId: "G-SPHDN6KBVN"
};
export const PAMA_FIREBASE = {
  apiKey: "AIzaSyCDTOfN5ImVZ71bkiDdf-fr2PFvLZkwhBE",
  authDomain: "pama-b7383.firebaseapp.com",
  databaseURL: "https://pama-b7383.firebaseio.com",
  projectId: "pama-b7383",
  storageBucket: "pama-b7383.appspot.com",
  messagingSenderId: "404674523438",
  appId: "1:404674523438:web:ebb37d54663789123e8abf",
  measurementId: "G-JG65ZEX5W7"
};
export const MANNER_FIREBASE = {
  apiKey: "AIzaSyAiaO6lA83cAypjJbHMzyZeQNURhWdTXqg",
  authDomain: "manner-1f3f8.firebaseapp.com",
  databaseURL: "https://manner-1f3f8-5d4e9.firebaseio.com/",
  projectId: "manner-1f3f8",
  storageBucket: "manner-1f3f8.appspot.com",
  messagingSenderId: "1065887792803",
  appId: "1:1065887792803:web:91f8f09217436eb1228916",
  measurementId: "G-Z85P6GYNFT"
};
export const SCHOOLLINK_FIREBASE = {
  apiKey: "AIzaSyA6oLm7EHHx7JK32pXeQP5O2oepjMBtIEA",
  authDomain: "school-link-bb0e9.firebaseapp.com",
  databaseURL: "https://school-link-bb0e9-default-rtdb.firebaseio.com",
  projectId: "school-link-bb0e9",
  storageBucket: "school-link-bb0e9.appspot.com",
  messagingSenderId: "154769077784",
  appId: "1:154769077784:web:545841844a217adb5c356d",
  measurementId: "G-4LPPLWK1Y1"

};
export const AUXSWOT_FIREBASE = {
  apiKey: "AIzaSyAASKQ2BUC4lHrmZlYIt2DLQn74SLEDvbw",
  authDomain: "auxswot-a62c2.firebaseapp.com",
  databaseURL: "https://auxswot-a62c2-default-rtdb.firebaseio.com",
  projectId: "auxswot-a62c2",
  storageBucket: "auxswot-a62c2.appspot.com",
  messagingSenderId: "153737149885",
  appId: "1:153737149885:web:4f06d625da54ccfa6fa6d4",
  measurementId: "G-Z85Z8P1HD8"
};
export const BVS_FIREBASE = {
  apiKey: "AIzaSyDdsmYAL21JRdrRJcylxYlWfUSfRN_JCk8",
  authDomain: "bvscloudv3.firebaseapp.com",
  databaseURL: "https://bvscloudv3.firebaseio.com",
  projectId: "bvscloudv3",
  storageBucket: "bvscloudv3.appspot.com",
  messagingSenderId: "417594338643",
  appId: "1:417594338643:web:5fdea6456b1f36bbf685a6",
  measurementId: "G-TMQ41MYN3N"
};
export const SSW_FIREBASE = {
  apiKey: "AIzaSyC5Y1xvR4hh07ocbAx1pjdR2bA-if0-CB0",
  authDomain: "ngsclouddev.firebaseapp.com",
  databaseURL: "https://ngsclouddev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ngsclouddev",
  storageBucket: "ngsclouddev.appspot.com",
  messagingSenderId: "965997608781",
  appId: "1:965997608781:web:bf6662688e823fc219c104",
  measurementId: "G-BYB6ZX7FDW"
};
export const PREKLEAP_FIREBASE = {
  apiKey: "AIzaSyBc0LAFBff2DSHvlVOGDrklcSOiyB0fIwY",
  authDomain: "ngs-preakleap.firebaseapp.com",
  projectId: "ngs-preakleap",
  databaseURL: "https://ngs-preakleap-default-rtdb.asia-southeast1.firebasedatabase.app/",
  storageBucket: "ngs-preakleap.appspot.com",
  messagingSenderId: "509897641010",
  appId: "1:509897641010:web:a557c3a847ddfdb14ac02b",
  measurementId: "G-W7KK849KYJ"

};
export const PREKANCHANH_FIREBASE = {
  apiKey: "AIzaSyAzBegSIUOcMdb4wk9ug9ZJac0xw6M2-eQ",
  authDomain: "ngs-prek-anchanh.firebaseapp.com",
  projectId: "ngs-prek-anchanh",
  databaseURL: "https://ngs-prek-anchanh-default-rtdb.asia-southeast1.firebasedatabase.app/",
  storageBucket: "ngs-prek-anchanh.appspot.com",
  messagingSenderId: "599738332182",
  appId: "1:599738332182:web:5a97c390a8f8f6b328b137",
  measurementId: "G-7MHXT5N8C9"
};
export const PEAMCHIKANG_FIREBASE = {
  apiKey: "AIzaSyCf0_Nu4LjJWXAhbTZKSVKjuc7d05cHpDU",
  authDomain: "ngs-peam-chi-kang.firebaseapp.com",
  databaseURL: "https://ngs-peam-chi-kang-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ngs-peam-chi-kang",
  storageBucket: "ngs-peam-chi-kang.appspot.com",
  messagingSenderId: "373846221515",
  appId: "1:373846221515:web:20311f424d65949382f14e",
  measurementId: "G-GYHWQ6FL3G",
  backupBucketId: 'ngs-peam-chi-kang-firestore-backup',
};
export const KAMPONGCHAM_FIREBASE = {
  apiKey: "AIzaSyAsB_vDLp6vZ9oH84Sh9LjKYJDcA_IKcvo",
  authDomain: "ngs-kampong-cham.firebaseapp.com",
  databaseURL: "https://ngs-kampong-cham-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "ngs-kampong-cham",
  storageBucket: "ngs-kampong-cham.appspot.com",
  messagingSenderId: "209218423343",
  appId: "1:209218423343:web:17222c57bfab487bf254da",
  measurementId: "G-EJ7CD5Z71F"
};
export const SPS_FIREBASE = {
  apiKey: "AIzaSyDH3eFolE9HUmNDbw3qN9GYrhjEhHiw0Po",
  authDomain: "spscloud-e9e6a.firebaseapp.com",
  databaseURL: "https://spscloud-e9e6a.firebaseio.com",
  projectId: "spscloud-e9e6a",
  storageBucket: "spscloud-e9e6a.appspot.com",
  messagingSenderId: "955845920778",
  appId: "1:955845920778:web:6ca737a7eb51a1cdda77f3",
  measurementId: "G-E20F4P1T1C"
};

export const SMS_FIREBASE = {
  apiKey: "AIzaSyAMGMC7LnKOg2I_WpKgnHpj9zUgMKbq_fc",
  authDomain: "school-sms-e8d07.firebaseapp.com",
  projectId: "school-sms-e8d07",
  storageBucket: "school-sms-e8d07.appspot.com",
  messagingSenderId: "951081845874",
  appId: "1:951081845874:web:a6903d1d196b99faefeeeb",
  measurementId: "G-D5MC3HDVRP",
  databaseURL: "https://school-sms-e8d07-default-rtdb.asia-southeast1.firebasedatabase.app",

};

export const SELECTED_FIREBASE_ENV = KAMPONGCHAM_FIREBASE;
